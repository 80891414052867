<template>
  <div class="main" data-title="角色管理" v-title>
    <el-card class="main-content" shadow="hover">
      <div class="menu-box">
        <div class="left-menu">
          <div class="group-head">
            <div class="group-name">角色列表</div>
            <el-tooltip class="item" content="添加新角色" effect="dark" placement="right">
              <i @click="addGroup()" class="el-icon-plus add-btn"></i>
            </el-tooltip>
          </div>
          <div
            :class="curr == item.group_id ? 'curr' : ''"
            :key="index"
            @click="updateGroup(item)"
            class="left-menu-item"
            v-for="(item, index) in groupList"
          >
            <span class="group-name">{{item.name}}</span>
            <span class="btns">
              <el-dropdown placement="bottom-start" trigger="click">
                <i class="el-icon-more"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="updateGroup(item)" v-has="'dept-edit'">修改角色</el-dropdown-item>
                  <el-dropdown-item
                    @click.native="removeGroup(item)"
                    class="btn-delete"
                    divided
                    v-has="'dept-remove'"
                  >删除角色</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>
        </div>
        <div class="right-main">
          <div class="body">
            <div class="form-main">
              <el-form :model="form" label-width="100px" ref="form" size="medium">
                <el-form-item label="角色名称" required>
                  <el-input placeholder="请输入角色名称" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input placeholder="请输入" v-model="form.memo"></el-input>
                </el-form-item>
                <el-form-item label="选择权限">
                  <div class="menu-tree">
                    <el-tree
                      check-strictly
                      :data="menuTree"
                      :props="defaultProps"
                      @check-change="handleCheckChange"
                      :default-checked-keys="form.menu_list"
                      default-expand-all
                      node-key="menu_id"
                      ref="tree"
                      show-checkbox
                    ></el-tree>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="form-footer">
              <el-button @click="submitForm()" type="primary">立即提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 'add',
      curr: 0,
      groupList: [],
      menuTree: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      form: {
        name: '',
        memo: '',
        menu_list: [],
      },
    }
  },
  mounted() {
    this.getGroup()
    this.getMenuTree()
  },
  methods: {
    getMenuTree() {
      this.$axios.post('/menu/lists').then((res) => {
        if (res.data.code == 200) {
          this.menuTree = res.data.data
        }
      })
    },
    handleCheckChange(data, checked, indeterminate) {
      this.form.menu_list = this.$refs.tree.getCheckedKeys()
      // console.log(this.$refs.tree.getCheckedKeys())
    },
    getGroup() {
      this.$axios.post('/group/lists').then((res) => {
        if (res.data.code == 200) {
          this.groupList = res.data.data
        }
      })
    },
    addGroup() {
      this.type = 'add'
      this.form = {
        name: '',
        memo: '',
        menu_list: [],
      }
      this.$refs.tree.setCheckedKeys([])
    },
    updateGroup(data) {
      console.log(data)
      this.type = 'edit'
      this.form.name = data.name
      this.form.memo = data.memo
      this.form.group_id = data.group_id
      this.curr = data.group_id
      if (data.menu_list) {
        this.form.menu_list = data.menu_list.split('|')
      }else{
        this.form.menu_list = []
      }
      this.$refs.tree.setCheckedKeys(this.form.menu_list)
    },
    removeGroup(data) {
      this.$confirm('确认删除「' + data.name + '」角色?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios
            .post('/group/remove', {
              group_id: data.group_id,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.getGroup()
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    submitForm() {
      if (this.form.name == '') {
        this.$message.error('角色名称不能为空')
        return false
      }

      let url = this.type == 'add' ? '/group/create' : '/group/edit'

      this.$axios.post(url, this.form).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.getGroup()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.main-content {
  /deep/ .el-card__body {
    padding-left: 0;
    padding-right: 0;
  }
}
.menu-box {
  display: flex;
  .left-menu {
    width: 224px;
  }
  .right-main {
    margin: 8px 40px;
    flex: 1;
    .head {
      display: flex;
      margin-bottom: 24px;
      .head-title {
        font-weight: 500;
        font-size: 20px;
        flex: 1;
      }
      .head-btns {
        display: flex;
        align-items: center;
        i.el-icon-check {
          font-size: 20px;
          color: #409eff;
        }
      }
    }
  }
}

.left-menu {
  .group-head {
    font-size: 18px;
    font-weight: bold;
    padding: 0 10px 0 24px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .group-name {
      flex: 1;
    }
  }
  border-right: 1px solid #e8e8e8;
  .left-menu-item {
    display: flex;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 24px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 8px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    &:hover {
      color: #409eff;
    }
    &.curr {
      background: #e6f7ff;
      font-weight: 500;
      color: #409eff;
      border-right: 3px #409eff solid;
    }
    .group-name {
      flex: 1;
    }
  }
}
.el-icon-more {
  transform: rotate(90deg);
  font-size: 12px;
  padding: 8px;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.65);
  }
}

.menu-tree {
  padding: 6px 0;
}
.form-footer {
  padding: 0 20px;
  text-align: right;
}
</style>